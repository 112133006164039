"use client";

import { Videos } from "@/components/Sections";
import { VideosEndpoints } from "@/graph/profile-videos.endpoints";
import useUserQuery from "@/hooks/useUserQuery";
import { useState, useEffect } from "react";

type ProfileProps = {
  userId: number;
};

export default function ProfileSectionVideosClient({
  userId,
}: ProfileProps) {
  const { data: video } = useUserQuery(userId, 'apg-user-videos-section', VideosEndpoints.get, { userId });
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    if (video && video.userId) {
      setIsDataReady(true);
    }
  }, [video]);

  return (
    <>
      {isDataReady ? (
        <div id="videos" className="w-full">
          <Videos data={video} />
        </div>
      ) : null}
    </>
  )
}