import { useEffect, useState } from 'react';
import Image from 'next/image';
import delay from '@apg.gg/core/lib/delay';
import { ApgUser } from '@/domain/account.interface';
import Modal from '@apg.gg/core/lib/Modal';
import { toast } from 'react-toastify';
import { RawDraftContentState } from 'draft-js';
import { Controller, useForm } from 'react-hook-form';
import textareaMeiliData from '@/lib/utils/textarea-meili';
import { useTranslations } from 'next-intl';
import useAuthStore from '@/store/auth.store';
import dynamic from 'next/dynamic';
import { useVouchRedeem } from '@/hooks/useRedeems';
import CoinPixelIcon from '@apg.gg/icons/lib/CoinPixelIcon';
import { ERROR_CODES } from '@/lib/utils/constants';
import PulseRingIcon from '@apg.gg/icons/lib/PulseRingIcon';

const VOUCH_COST = 500;

const DynamicTextarea = dynamic(() => import('@apg.gg/core/lib/Textarea'), {
  ssr: false,
})

interface FormData {
  testimonial: RawDraftContentState;
}

export interface VouchModalProps {
  isOpen: boolean;
  userProfile: ApgUser;
  onClose: () => void;
  onSuccess: () => void;
}

const VouchModal: React.FC<VouchModalProps> = ({
  isOpen,
  userProfile,
  onClose,
  onSuccess
}) => {
  const t = useTranslations();
  const { authToken, userId } = useAuthStore();
  const [showDrawer, setShowDrawer] = useState(false);
  const [search, setSearch] = useState<any>();
  const [prefix, setPrefix] = useState<string>('@');
  const useVouchRedeemMutation = useVouchRedeem({
    userId: userId as number,
    vouchedUserId: userProfile?.id as number,
    cost: VOUCH_COST,
    redeemId: 5,
    token: authToken
  });

  const {
    handleSubmit,
    control
  } = useForm<FormData>({
    mode: 'all',
    reValidateMode: 'onSubmit'
  });

  useEffect(() => {
    if (isOpen) {
      delay(() => setShowDrawer(true));
    }
  }, [isOpen])

  const handleOnClose = () => {
    setShowDrawer(false);
    delay(() => onClose());
  }

  const onSearch = async (searchedWord: string, prefix: string) => {
    setPrefix(prefix);

    const data = await textareaMeiliData(searchedWord, prefix);

    setSearch(data);
  }

  const onVouch = async (formData: FormData) => {
    const { success, message } = await useVouchRedeemMutation.mutateAsync(formData.testimonial);

    if (success) {
      toast.success(`${t('Profile.vouch-sended-successfully')} ${userProfile?.name}`);
      onSuccess();
      handleOnClose();
    } else {
      const errorMessage = ERROR_CODES(t)[message];
      toast.error(errorMessage);
      handleOnClose();
    }
  }

  return (
    <Modal
      prefixCls="apg-modal"
      isOpen={showDrawer}
      onClose={handleOnClose}
      title={t('Profile.vouch-modal-title', { username: userProfile.username })}
      content={
        <div className="flex flex-col gap-8">
          <span className="text-sm">{t('Profile.vouch-modal-description', { username: userProfile.username })}</span>

          <Controller
            control={control}
            name="testimonial"
            render={({ field: { onChange, value } }) => (
              <DynamicTextarea
                label={t('Profile.vouch-modal-input-label', { username: userProfile.username })}
                placeholder=""
                onChange={onChange}
                rawValue={value}
                maxLength={200}
                data={search}
                onSearch={onSearch}
                clearSearch={() => setSearch([])}
                renderItem={(item: any) => (
                  <>
                    {prefix === '@' && (
                      <>
                        <Image src={item.avatar} alt={item.name} className="w-10 h-10 rounded-full object-cover object-center" width={40} height={40} />
                        <div className="flex flex-col justify-center items-start gap-0.5">
                          <span className="font-bold text-white leading-tight">{item.name}</span>
                          <span className="text-white-400 text-sm leading-none">{`@${item.username}`}</span>
                        </div>
                      </>
                    )}
                    {prefix === '*' && (
                      <>
                        <Image src={item.avatar} alt={item.name} className="w-10 h-10 rounded object-cover object-center" width={40} height={40} />
                        <div className="flex flex-col justify-center items-start gap-0.5">
                          <span className="font-bold text-white leading-tight">{item.name}</span>
                          <span className="text-white-400 text-sm leading-none">Owner: {`@${item.username}`}</span>
                        </div>
                      </>
                    )}
                    {prefix === '&' && (
                      <>
                        <Image src={item.avatar} alt={item.name} className="w-10 h-10 rounded object-cover object-center" width={40} height={40} />
                        <div className="flex flex-col justify-center items-start gap-0.5">
                          <span className="font-bold text-white leading-tight">{item.name}</span>
                          <span className="text-white-400 text-sm leading-none">{`@${item.username}`}</span>
                        </div>
                      </>
                    )}
                    {prefix === ':' && (
                      <>
                        <Image src={item.avatar} alt={item.name} className="w-5 h-5 rounded object-cover object-center" width={20} height={20} />
                      </>
                    )}
                  </>
                )}
              />
            )}
            defaultValue={{ blocks: [], entityMap: {} }}
          />
        </div>
      }
      confirmText={
        <div className="flex items-center gap-1">
          {useVouchRedeemMutation.isPending ? <PulseRingIcon /> : <CoinPixelIcon />}
          <span>{VOUCH_COST} {t('Common.quest-coins-label')}</span>
        </div>
      }
      cancelText={t('Profile.button-cancel')}
      onConfirm={handleSubmit(onVouch)}
      centered
      classNames={{
        wrapper: "max-w-xl",
        header: "text-title-lg",
        content: "text-body-lg text-white-200"
      }}
    />
  );
};

export default VouchModal;
