import React, { FC, useRef } from "react";
import { useTiktokFeedVideoByUser } from "@/hooks/useSocials";
import Spinner from "@/components/ui/Spinner";
import useWindowSize from "@apg.gg/core/lib/useWindowSize";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { TiktokFeedVideo } from "@/domain/account/socials.interface";

const TiktokCarousel: FC<{ userId: number }> = ({ userId }) => {
  const { isXs, isSm } = useWindowSize();
  const mainSliderRef = useRef<any>(null);

  const mainSliderOptions = {
    type: "slide",
    height: isXs ? "355px" : isSm ? "300px" : "320px",
    width: "100%",
    fixedWidth: isXs ? "70%" : isSm ? "160px" : "200px",
    pagination: false,
    gap: "1rem",
    perPage: isXs ? 1 : isSm ? 3 : 4,
    perMove: 1,
    padding: "0"
  };

  const { tiktokFeedVideoByUser, isLoadingTiktokFeedVideoByUser } = useTiktokFeedVideoByUser({ userId });

  return (
    <div className="flex gap-4 w-full">
      {isLoadingTiktokFeedVideoByUser ? (
        <div className="flex items-center justify-center w-full h-96">
          <Spinner />
        </div>
      ) : (
        <div className="flex">
          <Splide options={mainSliderOptions} ref={mainSliderRef} className="games-carousel">
            {tiktokFeedVideoByUser?.map((video: TiktokFeedVideo) => (
              <SplideSlide key={`tiktok-video-${video.id}`} className="group relative overflow-hidden rounded-2xl">
                <div className="w-full h-full bg-black">
                  <iframe
                    src={video.embedLink}
                    title={video.title}
                    className="w-full h-full"
                  />
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      )}
    </div>
  );
};

export default React.memo(TiktokCarousel);
