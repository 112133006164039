"use client";

import { Achievements } from "@/components/Sections";
import useUserQuery from "@/hooks/useUserQuery";
import { useEffect, useState } from "react";
import { UserAchievement } from "@/domain/account/profile.interface";
import { AchievementsEndpoints } from "@/graph/profile-achievements.endpoints";

type ProfileProps = {
  userId: number;
};

export default function ProfileSectionAchievementsClient({
  userId,
}: ProfileProps) {
  const { data: achievements } = useUserQuery(userId, 'apg-user-achievements-section', AchievementsEndpoints.get<UserAchievement>, { userId: userId, limit: 3 });
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    if (achievements?.items && achievements?.items.length > 0) {
      setIsDataReady(true);
    }
  }, [achievements]);

  return (
    <>
      {isDataReady ? (
        <div id="achievements" className="w-full">
          <Achievements data={achievements} />
        </div>
      ) : null}
    </>
  )
}