import { apgGraphQL } from "./apgApi"
import { operationProfileVouch } from "./graph-queries";
import { RequestError } from "./general.endpoints";
import { operationProfileFollows } from "./graph-profile-queries";
import { InfinitePaginationResponse } from "@/domain/general.interface";
import { UserFollowType } from "@/domain/profile.interface";
import { RawDraftContentState } from "draft-js";

export const getError = ({ title, message, response }: RequestError): RequestError => ({ title, message, response })

export interface EditProfileSetProps<T> {
  userId?: number;
  token: string;
  data: T;
}

const getVouchUser = async ({
  vouchingUserId,
  vouchedUserId
}: {
  vouchingUserId: number;
  vouchedUserId: number;
}): Promise<boolean> => {
  const { data, errors } = await apgGraphQL(
    operationProfileVouch,
    'GetVouchUser',
    {
      "vouchingUserId": vouchingUserId,
      "vouchedUserId": vouchedUserId
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.userVouch[0] ? true : false;
}

const vouch = async ({
  vouchedUserId,
  testimonial,
  token
}: {
  vouchedUserId: number;
  testimonial: RawDraftContentState;
  token: string;
}): Promise<boolean> => {
  const { data, errors } = await apgGraphQL(
    operationProfileVouch,
    'Vouch',
    {
      "vouchedUserId": vouchedUserId,
      "testimonial": testimonial
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.follow.id ? true : false;
}

const getUserVouchs = async ({
  userId,
  followId,
  limit = 30, 
  page = 1
}: {
  userId: number,
  followId: number, 
  limit?: number, 
  page?: number
}): Promise<InfinitePaginationResponse<UserFollowType>> => {      
  const { data, errors } = await apgGraphQL(
    operationProfileFollows,
    'GetUserFollowers',
    {
      "userId": userId,
      "followId": followId,
      "limit": limit,
      "offset": (page - 1) * limit
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.userFollowers.aggregate.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  return {
    items: data.userFollowers.items,
    nextPage: page < totalPages ? page + 1 : null,
    previousPage: page > 0 ? page - 1 : null,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
  }
}

const getUserVouches = async ({
  userId,
  followId,
  limit = 30, 
  page = 1
}: {
  userId: number,
  followId: number, 
  limit?: number, 
  page?: number
}): Promise<InfinitePaginationResponse<UserFollowType>> => {      
  const { data, errors } = await apgGraphQL(
    operationProfileFollows,
    'GetUserFollowings',
    {
      "userId": userId,
      "followId": followId,
      "limit": limit,
      "offset": (page - 1) * limit
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.userFollowings.aggregate.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  return {
    items: data.userFollowings.items,
    nextPage: page < totalPages ? page + 1 : null,
    previousPage: page > 0 ? page - 1 : null,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
  }
}

export const ProfileVouchEndpoints = {
  getVouchUser,
  vouch,
  getUserVouchs,
  getUserVouches
}
