"use client";

import { ApgUser } from "@/domain/account.interface";
import { RawDraftContentState } from "draft-js";
import { About } from "@/components/Sections";

type ProfileProps = {
  userProfile: ApgUser | null;
};

export default function ProfileSectionAboutClient({
  userProfile,
}: ProfileProps) {


  const userAboutData = (userProfile: ApgUser) => {
    if (userProfile && userProfile.aboutRich) {
      const aboutRich: RawDraftContentState = userProfile.aboutRich;

      if ('blocks' in aboutRich && 'entityMap' in aboutRich) {
        if (Array.isArray(aboutRich.blocks) && aboutRich.blocks.length > 0) {
          return aboutRich;
        }
      }
    }

    return userProfile.about;
  }

  return (
    <>
      {userProfile && (userProfile.aboutRich || userProfile.about) ? (
        <div id="about" className="w-full">
          <About data={userAboutData(userProfile as ApgUser) || ''} />
        </div>
      ): null}
    </>
  )
}