"use client";

import { useEffect, useState } from "react";
import { useUserSectionData } from "@/hooks/useSocials";
import { UserSectionData } from "@/domain/account/socials.interface";
import TiktokFeed from "@/components/Sections/TiktokFeed";

type ProfileProps = {
  userId: number;
};

export default function ProfileSectionTiktokFeedClient({
  userId,
}: ProfileProps) {
  console.log("userId", userId);

  const { 
    userSectionData
  } = useUserSectionData({ 
    where: { 
      userId: {
        _eq: userId
      }, 
      sectionId: {
        _eq: 11
      },
      isActive: {
        _eq: true
      }
    }, 
    sectionId: 11,
    userId
  });
  
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    if (userSectionData?.data) {
      setIsDataReady(true);
    }
  }, [userSectionData]);

  return (
    <>
      {isDataReady ? (
        <div id="tiktok-feed" className="w-full">
          <TiktokFeed data={userSectionData as UserSectionData} />
        </div>
      ) : null}
    </>
  )
}