"use client";

import Spinner from "@/components/ui/Spinner";

export default function SectionLoader() {
  return (
    <div className="flex flex-col bg-black-900 px-9 py-8 rounded-2xl items-center justify-center w-full">
      <Spinner />
    </div>
  )
}