import MeiliSearch, { MultiSearchResponse } from "meilisearch";

const client = new MeiliSearch({
  host: `${process.env.NEXT_PUBLIC_MEILISEARCH_HOST}`,
  apiKey: `${process.env.NEXT_PUBLIC_MEILISEARCH_API_KEY}`,
})

const textareaMeiliData = async (searchedWord: string, prefix: string): Promise<any> => {
  const search: MultiSearchResponse<any> = await client.multiSearch({
    queries: [
      {
        indexUid: 'users-collections',
        q: searchedWord,
        limit: 16,
        attributesToSearchOn: ['username', 'name'],
        attributesToRetrieve: ['username', 'name', 'profileImage'],
      },
      {
        indexUid: 'events-collections',
        q: searchedWord,
        limit: 16,
        attributesToSearchOn: ['title', 'slug', 'user.name'],
        attributesToRetrieve: ['title', 'slug', 'user.name', 'image'],
      },
      {
        indexUid: 'games-collections',
        q: searchedWord,
        limit: 16,
        attributesToSearchOn: ['name', 'slug'],
        attributesToRetrieve: ['name', 'slug', 'thumbnail', 'id'],
      },
      {
        indexUid: 'hashtags-collections',
        q: searchedWord,
        limit: 16,
        attributesToSearchOn: ['hashtag'],
        attributesToRetrieve: ['hashtag', 'count', 'id'],
      },
    ]
  });

  let data;

  if (prefix === '@') {
    data = search?.results[0].hits.map((item: any) => ({
      avatar: item.profileImage || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`,
      name: item.username,
      username: item.username,
      slug: item.username,
      key: item.username,
      link: `/${item.username}`
    }))
  }

  if (prefix === '*') {
    data = search?.results[1].hits.map((item: any) => ({
      avatar: item.image || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`,
      name: item.title,
      username: item.user?.name || 'Unknown',
      slug: item.slug,
      key: `${item.id}-${item.slug}`,
      link: `/events/${item.slug}`
    }))
  }

  if (prefix === '&') {
    data = search?.results[2].hits.map((item: any) => ({
      avatar: item.thumbnail || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`,
      name: item.name,
      username: item.slug,
      slug: item.slug,
      key: `${item.id}-${item.slug}`,
      link: `/games/${item.slug}`
    }))
  }

  if (prefix === ':') {
    data = search?.results[2].hits.map((item: any) => ({
      avatar: item.thumbnail || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`,
      name: item.name,
      username: item.slug,
      slug: item.slug,
      key: `${item.id}-${item.slug}`
    }))
  }

  if (prefix === '#') {
    if (data = search?.results[3].hits.length > 0) {
      data = search?.results[3].hits.map((item: any) => ({
        name: item.hashtag,
        count: item.count,
        key: `${item.id}-${item.hashtag}`,
        link: `/hashtags/${item.hashtag}`
      }))
    } else {
      const randomInt = Math.floor(Math.random() * 1000000000);
      data = [{
        name: searchedWord,
        count: 1,
        isNew: true,
        key: `${randomInt}-${searchedWord}`,
        link: `/hashtags/${searchedWord}`
      }]
    }
  }

  return data;
}

export default textareaMeiliData;