import React, { FC } from "react";
import { useTiktokFeedVideoByUser } from "@/hooks/useSocials";
import Spinner from "@/components/ui/Spinner";
import { TiktokFeedVideo } from "@/domain/account/socials.interface";

const TiktokFeedStyle: FC<{ userId: number }> = ({ userId }) => {
  const { tiktokFeedVideoByUser, isLoadingTiktokFeedVideoByUser } = useTiktokFeedVideoByUser({ userId });

  return (
    <div className="flex gap-4 w-full">
      {isLoadingTiktokFeedVideoByUser ? (
        <div className="flex items-center justify-center w-full h-96">
          <Spinner />
        </div>
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 w-full justify-start">
          {tiktokFeedVideoByUser?.map((video: TiktokFeedVideo) => (
            <div key={`tiktok-video-${video.id}`} className="group relative overflow-hidden rounded-2xl">
              <div className="w-full h-96 bg-black">
                <iframe
                  src={video.embedLink}
                  title={video.title}
                  className="w-full h-96"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(TiktokFeedStyle);
