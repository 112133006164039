import { UserSectionData } from "@/domain/account/socials.interface";
import { SocialEndpoints } from "@/graph/socials/endpoints";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useSocialFollowersCount = ({
  userId
}: {
  userId: number
}) => {
  const { 
    data: socialFollowersCount, 
    isLoading: isLoadingSocialFollowersCount,
    refetch: refetchSocialFollowersCount 
  } = useQuery({ 
    queryKey: ["apg-social-followers-count", userId],
    queryFn: () => SocialEndpoints.getSocialFollowersCount({ userId }),
    staleTime: 1000 * 60 * 10
  })

  return {
    socialFollowersCount,
    isLoadingSocialFollowersCount,
    refetchSocialFollowersCount
  }
}

export const useTiktokFeedVideoByUser = ({
  userId
}: {
  userId: number
}) => {
  const { 
    data: tiktokFeedVideoByUser, 
    isLoading: isLoadingTiktokFeedVideoByUser,
    refetch: refetchTiktokFeedVideoByUser 
  } = useQuery({ 
    queryKey: ["apg-tiktok-feed-video-by-user", userId],
    queryFn: () => SocialEndpoints.getTiktokFeedVideoByUser({ userId }),
    staleTime: 1000 * 60 * 10
  })

  return {
    tiktokFeedVideoByUser,
    isLoadingTiktokFeedVideoByUser,
    refetchTiktokFeedVideoByUser
  }
}

export const useTwitchUserOnline = ({
  token
} : {
  token: string
}) => {
  const { 
    data: twitchUserOnline, 
    isLoading: isLoadingTwitchUserOnline,
    refetch: refetchTwitchUserOnline 
  } = useQuery({ 
    queryKey: ["apg-twitch-user-online"],
    queryFn: () => SocialEndpoints.getTwitchUserOnline({ token }),
    staleTime: 1000 * 60 * 10
  })

  return {
    twitchUserOnline,
    isLoadingTwitchUserOnline,
    refetchTwitchUserOnline
  }
}

export const useUserLogins = ({
  where,
  token
} : {
  where: Record<string, any>
  token: string
}) => {
  const { 
    data: userLogins, 
    isLoading: isLoadingUserLogins,
    refetch: refetchUserLogins 
  } = useQuery({ 
    queryKey: ["apg-user-logins"],
    queryFn: () => SocialEndpoints.getUserLogins({ where, token })
  })

  return {
    userLogins,
    isLoadingUserLogins,
    refetchUserLogins
  }
}

export const useUserSectionData = ({
  where,
  sectionId,
  userId,
  token
} : {
  where: Record<string, any>
  sectionId: number
  userId: number
  token?: string
}) => {
  const { 
    data: userSectionData, 
    isLoading: isLoadingUserSectionData,
    refetch: refetchUserSectionData 
  } = useQuery({ 
    queryKey: ["apg-user-section-data", userId, sectionId],
    queryFn: () => SocialEndpoints.getUserSectionData({ where, token }),
    staleTime: 1000 * 60 * 10
  })

  return {
    userSectionData,
    isLoadingUserSectionData,
    refetchUserSectionData
  }
}

export const useInsertUserSectionDataOne = ({
  token
} : {
  token: string
}) => {
  return useMutation(
    {
      mutationFn: (object: Partial<UserSectionData>) => SocialEndpoints.insertUserSectionData({ object, token }),
      onMutate: () => {
        return true
      },
      onSuccess: () => {
        return true
      }
    }
  );
}

export type UpdateUserSectionDataByPk = {
  id: number,
  sectionId: number,
  userId: number,
  set: Partial<UserSectionData>
}

export const useUpdateUserSectionDataByPk = ({
  token
} : {
  token: string
}) => {
  return useMutation(
    {
      mutationFn: ({ id, sectionId, userId, set } : UpdateUserSectionDataByPk) => SocialEndpoints.updateUserSectionData({ id, sectionId, userId, set, token }),
      onMutate: () => {
        return true
      },
      onSuccess: () => {
        return true
      }
    }
  );
}