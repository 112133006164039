
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { ApgUser } from "@/domain/account.interface";
import { RawDraftContentState } from "draft-js";
import { RedeemEndpoints } from "@/graph/redeem/reddems-endpoints";
import { Event } from "@/domain/account/events.interface";

type RedeemProps = {
  userId: number;
  cost: number;
  redeemId: number;
  token: string;
}

export const useVouchRedeem = ({
  userId,
  vouchedUserId,
  cost,
  redeemId,
  token
}: RedeemProps & {
  vouchedUserId: number;
}) => {
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData(['apg-user-signed']) as ApgUser;

  return useMutation(
    {
      mutationFn: (testimonial: RawDraftContentState) => RedeemEndpoints.vouch({ userId, vouchedUserId, testimonial, cost, redeemId, token }),
      onMutate: () => {
        return userData.totalCoins < cost
      },
      onSuccess: (data) => {
        queryClient.refetchQueries({ queryKey: ['apg-user-signed']});
      },
    }
  );
}

export const useEventRedeem = ({
  userId,
  cost,
  redeemId,
  token
}: RedeemProps) => {
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData(['apg-user-signed']) as ApgUser;

  return useMutation(
    {
      mutationFn: (event: Partial<Event>) => RedeemEndpoints.event({ userId, event, cost, redeemId, token }),
      onMutate: () => {
        return userData.totalCoins < cost
      },
      onSuccess: (data) => {
        queryClient.refetchQueries({ queryKey: ['apg-user-signed']});
      },
    }
  );
}