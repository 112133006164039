"use client";

import { Gallery } from "@/components/Sections";
import { UserAlbum } from "@/domain/account/galleries.interface";
import { GalleriesEndpoints } from "@/graph/profile-galleries.endpoints";
import useUserQuery from "@/hooks/useUserQuery";
import { useState, useEffect } from "react";

type ProfileProps = {
  userId: number;
};

export default function ProfileSectionGalleriesClient({
  userId,
}: ProfileProps) {
  const { data: galleries } = useUserQuery(userId, 'apg-user-galleries-section', GalleriesEndpoints.get<UserAlbum>, { userId, isEditing: false });
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    if (galleries?.items && galleries?.items.length > 0) {
      setIsDataReady(true);
    }
  }, [galleries]);

  return (
    <>
      {isDataReady ? (
        <div id="galleries" className="w-full">
          <Gallery data={galleries} />
        </div>
      ) : null}
    </>
  )
}