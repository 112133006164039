import { ReactNode } from "react";
import TiktokCarouselStyle from "./TiktokStyle/Carousel";
import TiktokFeedStyle from "./TiktokStyle/Feed";
import { useTranslations } from "next-intl";
import { UserSectionData } from "@/domain/account/socials.interface";

interface TiktokFeedProps {
  data: UserSectionData;
  extras?: ReactNode
}

const TiktokFeed: React.FC<TiktokFeedProps> = ({ data: userSectionData, extras }) => {
  const t = useTranslations('Profile');
  const { userId, data } = userSectionData || {};
  
  return (
    <div className="flex flex-col bg-black-900 p-4 lg:p-8 gap-6 rounded-2xl items-center">
      {/* Header section */}
      <div className="flex items-center justify-between w-full">
        <div className="flex flex-col">
          <h1 className="text-white text-title-lg uppercase">{t('section-tiktok-feed-title')}</h1>
          <span className="gradient-1 h-2 w-24 rounded-2xl"></span>
        </div>
        {extras}
      </div>

      {/* Content section */}
      <div className="flex w-full">
        {data.template === 'carousel' ? <TiktokCarouselStyle userId={userId} /> : null}
        {data.template === 'feed' ? <TiktokFeedStyle userId={userId} /> : null}
      </div>
    </div>
  );
};

export default TiktokFeed;
