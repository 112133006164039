"use client";

import { useTranslations } from "next-intl";
import { ApgUser } from "@/domain/account.interface";
import { ProfileEndpoints } from "@/graph/profile.endpoints";
import { ProfileVouchEndpoints } from "@/graph/profile.vouch.endpoints";
import { useGetProfileUser } from "@/hooks/useUser/useUser";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import ProfileHeader from "@/components/ProfileHeader";
import VouchModal from "@/components/Modals/VouchModal";
import Modal from "@apg.gg/core/lib/Modal";
import useAuthStore from "@/store/auth.store";

type ProfileProps = {
  profile: string;
  user: ApgUser | null;
  userProfile: ApgUser | null;
  isFollowing: boolean;
  isIdol: boolean;
  isVouched: boolean;
  random: number;
};

export default function ProfileHeaderClient({
  profile,
  user,
  userProfile,
  isFollowing,
  isIdol,
  isVouched,
  random
}: ProfileProps) {
  const t = useTranslations('Profile');

  const { authToken } = useAuthStore();
  const { data: profileUser, isLoading, refetch: refetchProfile } = useGetProfileUser(profile as string, userProfile as ApgUser);
  const [showUnfollowModal, setShowUnfollowModal] = useState(false);
  const [showBecomeFanModal, setShowBecomeFanModal] = useState(false);
  const [showVouchModal, setShowVouchModal] = useState(false);

  const { data: isFollowingUser, refetch: refetchFollow } = useQuery({
    queryKey: ['apg-user-follow', userProfile?.id],
    queryFn: () => ProfileEndpoints.getFollowUser({ 
      userId: user?.id || 0,
      followingId: userProfile?.id as number,
    }),
    initialData: isFollowing,
    staleTime: 1000 * 60 * 10,
  });

  const { data: isMyIdol, refetch: refetchFan } = useQuery({
    queryKey: ['apg-user-fan', userProfile?.id],
    queryFn: () => ProfileEndpoints.getFanUser({ 
      fanUserId: user?.id || 0,
      idolUserId: userProfile?.id as number,
    }),
    initialData: isIdol,
    staleTime: 1000 * 60 * 10,
  });

  const { data: isVouchedUser, refetch: refetchVouch } = useQuery({
    queryKey: ['apg-user-vouch', userProfile?.id],
    queryFn: () => ProfileVouchEndpoints.getVouchUser({ 
      vouchingUserId: user?.id || 0,
      vouchedUserId: userProfile?.id as number,
    }),
    initialData: isVouched,
    staleTime: 1000 * 60 * 10,
  });

  const onFollow = async () => {
    try {
      await ProfileEndpoints.follow({
        followingId: userProfile?.id as number,
        token: authToken
      });

      toast.success(`${t('followed-successfully')} ${userProfile?.name}`);
      refetchFollow();
      refetchProfile();
    } catch (error) {
      toast.error(`${t('followed-failed')} ${userProfile?.name}`);
    }
  }

  const onUnfollow = async () => {
    try {
      await ProfileEndpoints.unfollow({
        userId: user?.id as number,
        followingId: userProfile?.id as number,
        token: authToken
      });
      toast.success(`${t('unfollowed-successfully')} ${userProfile?.name}`);
      refetchFollow();
      refetchProfile();
    } catch (error) {
      toast.error(`${t('unfollowed-failed')} ${userProfile?.name}`);
    }
  }

  const onFan = async () => {
    try {
      await ProfileEndpoints.fan({
        idolUserId: userProfile?.id as number,
        token: authToken
      });

      toast.success(`${t('become-fan-successfully')} ${userProfile?.name}`);
      refetchFan();
      refetchProfile();
    } catch (error) {
      toast.error(`${t('become-fan-failed')} ${userProfile?.name}`);
    }
  }

  const onUnfan = async () => {
    try {
      await ProfileEndpoints.unfan({
        fanUserId: user?.id as number,
        idolUserId: userProfile?.id as number,
        token: authToken
      });
      toast.success(`${t('unfan-successfully')} ${userProfile?.name}`);
      refetchFan();
      refetchProfile();
    } catch (error) {
      toast.error(`${t('unfan-failed')} ${userProfile?.name}`);
    }
  }

  if (isLoading) return null;

  return (
    <>
      <ProfileHeader 
        user={profileUser} 
        showFollowButton={!!user && user.id !== userProfile?.id} 
        isVerified={user?.isVerified}
        isFollowing={isFollowingUser} 
        isMyIdol={isMyIdol}
        isVouched={isVouchedUser}
        onFollow={onFollow} 
        onUnfollow={() => setShowUnfollowModal(true)}
        onFan={() => setShowBecomeFanModal(true)}
        onUnfan={onUnfan}
        onVouch={() => setShowVouchModal(true)}
      />

      {/* Unfollow Modal */}
      {showUnfollowModal ? (
        <Modal
          prefixCls="apg-modal"
          isOpen={showUnfollowModal}
          onClose={() => setShowUnfollowModal(false)}
          title={t('unfollow-modal-title', {
            username: userProfile?.username || profile as string
          })}
          content={t('unfollow-modal-description', {
            username: userProfile?.username || profile as string
          })}
          confirmText={t('button-unfollow')}
          cancelText={t('button-cancel')}
          onConfirm={onUnfollow}
          centered
          classNames={{
            wrapper: "max-w-xs",
            header: "text-title-lg",
            content: "text-body-lg text-white-200"
          }}
        />
      ) : null}

      {/* Become Fan Modal */}
      {showBecomeFanModal ? (
        <Modal
          prefixCls="apg-modal"
          isOpen={showBecomeFanModal}
          onClose={() => setShowBecomeFanModal(false)}
          title={t('fan-modal-title',{
            username: userProfile?.username || profile as string
          })}
          content={t('fan-modal-description', {
            username: userProfile?.username || profile as string
          })}
          confirmText={t('button-become-fan')}
          cancelText={t('button-cancel')}
          onConfirm={onFan}
          centered
          classNames={{
            wrapper: "max-w-xs",
            header: "text-title-lg",
            content: "text-body-lg text-white-200"
          }}
        />
      ) : null}

      {/* Vouch Modal */}
      {showVouchModal ? (
        <VouchModal
          isOpen={showVouchModal}
          userProfile={userProfile as ApgUser}
          onClose={() => setShowVouchModal(false)}
          onSuccess={() => {
            setShowVouchModal(false);
            refetchVouch();
            refetchProfile();
          }}
        />
      ) : null}
    </>
  )
}