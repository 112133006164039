export const operationSocials = `
  query GetSocialFollowersCount($userId: Int!) @cached {
    getSocialFollowersCount: general_getSocialFollowersCount(userId: $userId) {
      totalFollowers
      tiktokFollowers
      twitchFollowers
      facebookFollowers
      instagramFollowers
      kofiFollowers
      patreonFollowers
      twitterFollowers
      youtubeFollowers
    }
  }

  query GetTiktokFeedVideoByUser($userId: Int!) @cached {
    tiktokFeedVideoByUser: general_tiktokFeedVideoByUser(userId: $userId) {
      id
      title
      coverImageUrl
      duration
      height
      width
      embedHtml
      embedLink
      likeCount
      viewCount
      shareCount
      commentCount
    }
  }

  query GetTwitchUserOnline @cached {
    twitchUserOnline: general_twitchUserOnline {
      id
      gameId
      gameName
      isMature
      thumbnailUrl
      title
      userId
      userLogin
      userName
      viewerCount
    }
  }

  query GetUserLogins($where: user_logins_bool_exp = {}) @cached {
    userLogins: user_logins(where: $where) {
      id
      userId
      email
      loginProviderId
      accessToken
      refreshToken
      providerId
    }
  }

  query GetUserSectionData($where: user_section_data_bool_exp = {}) @cached {
    userSectionData: user_section_data(where: $where) {
      id
      userId
      sectionId
      isActive
      data
      updatedAt
      createdAt
    }
  }

  mutation InsertUserSectionDataOne($object: user_section_data_insert_input = {}) {
    insertUserSectionDataOne: insert_user_section_data_one(object: $object) {
      id
      data
    }
  }

  mutation UpdateUserSectionDataByPk($id: Int!, $sectionId: Int!, $userId: Int!, $_set: user_section_data_set_input = {}) {
    updateUserSectionDataByPk: update_user_section_data_by_pk(pk_columns: {id: $id, sectionId: $sectionId, userId: $userId}, _set: $_set) {
      id
      data
    }
  }
`;