"use client";

import { Games } from "@/components/Sections";
import { GamesEndpoints } from "@/graph/profile-games.endpoints";
import useUserQuery from "@/hooks/useUserQuery";
import { useState, useEffect } from "react";

type ProfileProps = {
  userId: number;
};

export default function ProfileSectionGamesClient({
  userId,
}: ProfileProps) {
  const { data: games } = useUserQuery(userId, 'apg-user-games-section', GamesEndpoints.get, { userId, isPrivate: true });
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    if (games && games.length > 0) {
      setIsDataReady(true);
    }
  }, [games]);

  return (
    <>
      {isDataReady ? (
        <div id="games" className="w-full">
          <Games data={games} />
        </div>
      ) : null}
    </>
  )
}