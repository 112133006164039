"use client";

import { Partners } from "@/components/Sections";
import useUserQuery from "@/hooks/useUserQuery";
import { useEffect, useState } from "react";
import { UserPartner } from "@/domain/account/partners.interface";
import { PartnersEndpoints } from "@/graph/profile-partners.endpoints";

type ProfileProps = {
  userId: number;
};

export default function ProfileSectionPartnersClient({
  userId,
}: ProfileProps) {
  const { data: partners } = useUserQuery(userId, 'apg-user-partners-section', PartnersEndpoints.get<UserPartner>, { userId });
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    if (partners?.items && partners?.items.length > 0) {
      setIsDataReady(true);
    }
  }, [partners]);

  return (
    <>
      {isDataReady ? (
        <div id="partners" className="w-full">
          <Partners data={partners} />
        </div>
      ) : null}
    </>
  )
}