export const operationRedeem = `
  query GetRedeems {
    rewards_redemptions(where: {active: {_eq: true}}) {
      active
      categoryId
      costCoins
      description
      id
      imageUrl
      name
    }
  }

  mutation ValidateUserCoins($payload: UpdateUserCoinsInput = {}) {
    updateUserCoins(payload: $payload) {
      success
    }
  }
`