import { apgGraphQL } from "./apgApi"
import { operationProfilePartner } from "./graph-queries";
import { RequestError } from "./general.endpoints";
import { PaginationResponse } from "@/domain/general.interface";
import { EditProfileSetProps } from "./profile.endpoints";
import { Partner } from "@/domain/account/partners.interface";

export const getError = ({ title, message, response }: RequestError): RequestError => ({ title, message, response })

const get = async <T>({
  userId,
  token,
  limit = 4,
  page = 1,
  isEditing = false
}: any): Promise<PaginationResponse<T>> => {
  const offset = (page - 1) * limit;
  const showOnlyActive = !isEditing ? { "isActive": { "_eq": true } } : {};

  const { data, errors } = await apgGraphQL(
    operationProfilePartner,
    'GetUserPartners',
    {
      "where": {
        "userId": { "_eq": userId },
        ...showOnlyActive
      },
      "limit": limit,
      "offset": offset
    },
    token
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.pagination.data.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  return {
    items: data.userPartners,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
    hasPreviousPage: page > 1,
    hasNextPage: page < totalPages
  };
}

const insert = async ({ data: userPartner, userId, token }: EditProfileSetProps<Partial<any>>): Promise<Partner> => {
  const { isFeature, ...event } = userPartner;

  const { data: { insertPartner }, errors: er } = await apgGraphQL(
    operationProfilePartner,
    'InsertPartner',
    {
      "object": event
    },
    token
  )

  if (er) {
    throw getError(er[0] as RequestError)
  }

  if (!insertPartner) {
    throw getError({
      title: 'Error',
      message: 'Partner not found',
      response: {
        status: 404
      }
    })
  }

  const { id } = insertPartner;

  const { data, errors } = await apgGraphQL(
    operationProfilePartner,
    'InsertUserPartner',
    {
      "object": {
        partnerId: id,
        isFeature
      }
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.insertUserPartner;
}

const update = async ({ data: userPartner, userId, token }: EditProfileSetProps<Partial<any>>): Promise<Partner> => {
  const { isFeature, isActive, partner } = userPartner;
  const { id, ...rest } = partner;

  const { data: updateUserPartner, errors: er } = await apgGraphQL(
    operationProfilePartner,
    'UpdateUserPartner',
    {
      "_set": {
        "isFeature": isFeature,
        "isActive": isActive
      },
      "userId": userId,
      "partnerId": id
    },
    token
  )

  if (er) {
    throw getError(er[0] as RequestError)
  }

  if (!updateUserPartner) {
    throw getError({
      title: 'Error',
      message: 'Partner not found',
      response: {
        status: 404
      }
    })
  }

  const { data, errors } = await apgGraphQL(
    operationProfilePartner,
    'UpdatePartner',
    {
      "_set": rest,
      "id": id,
    },
    token
  )

  if (errors) {
    throw getError(er[0] as RequestError)
  }

  return data.updatePartner;
}

const remove = async ({ partnerId, userId, token }: any): Promise<boolean> => {
  const { data, errors } = await apgGraphQL(
    operationProfilePartner,
    'DeleteUserPartner',
    {
      "partnerId": partnerId,
      "userId": userId
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return true;
}

export const PartnersEndpoints = {
  get,
  insert,
  update,
  remove
}
