import { SocialFollowersCount, TiktokFeedVideo, TwitchUserOnline, UserLogin, UserSectionData } from "@/domain/account/socials.interface";
import { apgGraphQL } from "../apgApi"
import { RequestError } from "../general.endpoints";
import { operationSocials } from "./queries";

export const getError = ({ title, message, response }: RequestError): RequestError => ({ title, message, response })

const getSocialFollowersCount = async ({
  userId
}: {
  userId: number
}): Promise<SocialFollowersCount> => {
  const { data, errors } = await apgGraphQL(
    operationSocials,
    "GetSocialFollowersCount",
    {
      "userId": userId
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.getSocialFollowersCount;
}

const getTiktokFeedVideoByUser = async ({
  userId
}: {
  userId: number
}): Promise<TiktokFeedVideo[]> => {
  const { data, errors } = await apgGraphQL(
    operationSocials,
    "GetTiktokFeedVideoByUser",
    {
      "userId": userId
    }
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.tiktokFeedVideoByUser;
}

const getTwitchUserOnline = async ({
  token
} : {
  token: string
}): Promise<TwitchUserOnline[]> => {
  const { data, errors } = await apgGraphQL(
    operationSocials,
    "GetTwitchUserOnline",
    {},
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.twitchUserOnline;
}

const getUserLogins = async ({
  where,
  token
} : {
  where: Record<string, any>,
  token: string
}): Promise<UserLogin[]> => {
  const { data, errors } = await apgGraphQL(
    operationSocials,
    "GetUserLogins",
    {
      "where": where
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.userLogins;
}

const getUserSectionData = async ({
  where,
  token
} : {
  where: Record<string, any>,
  token?: string
}): Promise<UserSectionData> => {
  const { data, errors } = await apgGraphQL(
    operationSocials,
    "GetUserSectionData",
    {
      "where": where
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.userSectionData[0];
}

const insertUserSectionData = async ({
  object,
  token
}: {
  object: Partial<UserSectionData>,
  token: string
}): Promise<UserSectionData> => {
  const { data, errors } = await apgGraphQL(
    operationSocials,
    "InsertUserSectionDataOne",
    {
      "object": object
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.insertUserSectionDataOne;
}

const updateUserSectionData = async ({
  id,
  sectionId,
  userId,
  set,
  token
} : {
  id: number,
  sectionId: number,
  userId: number,
  set: Partial<UserSectionData>,
  token: string
}): Promise<UserSectionData> => {
  const { data, errors } = await apgGraphQL(
    operationSocials,
    "UpdateUserSectionDataByPk",
    {
      "id": id,
      "sectionId": sectionId,
      "userId": userId,
      "_set": set
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.updateUserSectionDataByPk;
}

export const SocialEndpoints = {
  getSocialFollowersCount,
  getTiktokFeedVideoByUser,
  getTwitchUserOnline,
  getUserLogins,
  getUserSectionData,
  insertUserSectionData,
  updateUserSectionData
}
