"use client";

import { TotalFollowers } from "@/components/Sections";
import { useEffect, useState } from "react";
import { useSocialFollowersCount, useUserSectionData } from "@/hooks/useSocials";
import { SocialFollowersCount, UserSectionData } from "@/domain/account/socials.interface";

type ProfileProps = {
  userId: number;
};

export default function ProfileSectionTotalFollowersClient({
  userId,
}: ProfileProps) {
  const { socialFollowersCount, isLoadingSocialFollowersCount } = useSocialFollowersCount({
    userId: userId as number,
  });

  const { 
    userSectionData
  } = useUserSectionData({ 
    where: { 
      userId: {
        _eq: userId
      }, 
      sectionId: {
        _eq: 12
      },
      isActive: {
        _eq: true
      }
    }, 
    sectionId: 12,
    userId
  });
  
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    if (socialFollowersCount) {
      setIsDataReady(true);
    }
  }, [socialFollowersCount]);

  return (
    <>
      {isDataReady ? (
        <div id="total-followers" className="w-full">
          <TotalFollowers 
            data={socialFollowersCount as SocialFollowersCount} 
            isLoading={isLoadingSocialFollowersCount}
            userSectionData={userSectionData as UserSectionData}
          />
        </div>
      ) : null}
    </>
  )
}