import { apgGraphQL } from "./apgApi"
import { RequestError } from "./general.endpoints";
import { PaginationResponse } from "@/domain/general.interface";
import { EditProfileSetProps } from "./profile.endpoints";
import { operationProfileGalleries } from "./graph-profile-queries";
import { UserAlbum } from "@/domain/account/galleries.interface";

export const getError = ({ title, message, response }: RequestError): RequestError => ({ title, message, response })

const get = async <T>({
  userId,
  token,
  limit = 4,
  page = 1,
  isEditing = false
}: any): Promise<PaginationResponse<T>> => {
  const offset = (page - 1) * limit;
  const showActive = isEditing ? {} : { "isActive": { "_eq": true } };

  const { data, errors } = await apgGraphQL(
    operationProfileGalleries,
    'GetUserAlbums',
    {
      "where": {
        "userId": { "_eq": userId },
        ...showActive
      },
      "limit": limit,
      "offset": offset
    },
    token
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.pagination.data.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  return {
    items: data.userAlbums,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
    hasPreviousPage: page > 1,
    hasNextPage: page < totalPages
  };
}

const insert = async ({ data: userAlbum, token }: EditProfileSetProps<Partial<any>>): Promise<UserAlbum> => {
  const { fileIds, ...album } = userAlbum;

  const { data: { insertAlbum }, errors: er } = await apgGraphQL(
    operationProfileGalleries,
    'InsertAlbum',
    {
      "object": album
    },
    token
  )

  if (er) {
    throw getError(er[0] as RequestError)
  }

  if (!insertAlbum) {
    throw getError({
      title: 'Error',
      message: 'Album not found',
      response: {
        status: 404
      }
    })
  }

  const { id } = insertAlbum;

  const albumFiles = fileIds.map((fileId: number, index: number) => ({
    albumId: id,
    fileId: fileId,
    order: index + 1,
    isActive: true,
    isPinned: false
  }))

  const { data: { insertAlbumFiles }, errors: albumFileErrors } = await apgGraphQL(
    operationProfileGalleries,
    'InsertAlbumFiles',
    {
      "objects": albumFiles
    },
    token
  )

  if (albumFileErrors) {
    throw getError(albumFileErrors[0] as RequestError)
  }

  if (!insertAlbumFiles) {
    throw getError({
      title: 'Error',
      message: 'Got a problem inserting album files',
      response: {
        status: 404
      }
    })
  }

  const { data, errors } = await apgGraphQL(
    operationProfileGalleries,
    'InsertUserAlbum',
    {
      "object": {
        albumId: id,
        isActive: album.isActive,
      }
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.insertUserAlbum;
}

const update = async ({ data: userAlbum, userId, token }: EditProfileSetProps<Partial<any>>): Promise<UserAlbum> => {
  const { isPinned, isActive, isPublished, id, ...album } = userAlbum;

  const { data: updateUserAlbum, errors: er } = await apgGraphQL(
    operationProfileGalleries,
    'UpdateUserAlbum',
    {
      "_set": {
        "isPinned": isPinned,
        "isActive": isActive,
        "isPublished": isPublished
      },
      "userId": userId,
      "albumId": id
    },
    token
  )

  if (er) {
    throw getError(er[0] as RequestError)
  }

  if (!updateUserAlbum) {
    throw getError({
      title: 'Error',
      message: 'Album not found',
      response: {
        status: 404
      }
    })
  }

  const { data, errors } = await apgGraphQL(
    operationProfileGalleries,
    'UpdateAlbum',
    {
      "_set": album,
      "id": id,
    },
    token
  )

  if (errors) {
    throw getError(er[0] as RequestError)
  }

  return data.updateAlbum;
}

const remove = async ({ albumId, userId, token }: any): Promise<boolean> => {
  const { data, errors } = await apgGraphQL(
    operationProfileGalleries,
    'DeleteUserAlbum',
    {
      "albumId": albumId,
      "userId": userId
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return true;
}

export const GalleriesEndpoints = {
  get,
  insert,
  update,
  remove
}
