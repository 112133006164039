import { ReactNode, useEffect, useRef } from "react";
import { useTranslations } from "next-intl";
import { UserSectionData } from "@/domain/account/socials.interface";

interface TwitchPlayerProps {
  data: UserSectionData;
  extras?: ReactNode;
}

const TwitchPlayer: React.FC<TwitchPlayerProps> = ({ data: userSectionData, extras }) => {
  const t = useTranslations('Profile');
  const { data } = userSectionData || {};
  const embedInitialized = useRef(false);
  const prevUserLogin = useRef(data.userLogin);

  useEffect(() => {
    const existingScript = document.querySelector('script[src="https://embed.twitch.tv/embed/v1.js"]');

    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://embed.twitch.tv/embed/v1.js";
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        initializeTwitchPlayer();
      };

      return () => {
        document.body.removeChild(script);
      };
    } else {
      initializeTwitchPlayer();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.userLogin]);

  const initializeTwitchPlayer = () => {
    if (embedInitialized.current && prevUserLogin.current === data.userLogin) return;

    const embedDiv = document.getElementById("twitch-embed");
    if (embedDiv) embedDiv.innerHTML = "";

    new window.Twitch.Embed("twitch-embed", {
      width: "100%",
      height: "100%",
      channel: data.userLogin,
      layout: "video",
      autoplay: true,
    });

    embedInitialized.current = true;
    prevUserLogin.current = data.userLogin;
  };

  return (
    <div className="flex flex-col bg-black-900 p-4 lg:p-8 gap-6 rounded-2xl items-center">
      <div className="flex items-center justify-between w-full">
        <div className="flex flex-col">
          <h1 className="text-white text-title-lg uppercase">{t('section-twitch-player-title')}</h1>
          <span className="gradient-1 h-2 w-24 rounded-2xl"></span>
        </div>
        {extras}
      </div>

      <div className="flex w-full">
        <div id="twitch-embed" className="w-full aspect-video"></div>
      </div>
    </div>
  );
};

export default TwitchPlayer;
