"use client";

import { useTranslations } from "next-intl";
import { ApgUser, UserSection } from "@/domain/account.interface";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import Image from "next/image";
import ErrorSectionFallback from "../ErrorSection";
import slugify from "@apg.gg/core/lib/slugify";
import ProfileSectionAboutClient from "./sections/about";
import SectionLoader from "../SectionLoader";
import ProfileSectionAchievementsClient from "./sections/achievements";
import ProfileSectionEventsClient from "./sections/events";
import ProfileSectionGalleriesClient from "./sections/galleries";
import ProfileSectionGamesClient from "./sections/games";
import ProfileSectionPartnersClient from "./sections/partners";
import ProfileSectionSetupClient from "./sections/setup";
import ProfileSectionVideosClient from "./sections/videos";
import logError from "@/lib/utils/logError";
import ProfileSectionTotalFollowersClient from "./sections/total-followers";
import ProfileSectionTwitchPlayerClient from "./sections/twitch-player";
import ProfileSectionTiktokFeedClient from "./sections/tiktok-feed";

type ProfileProps = {
  userProfile: ApgUser | null;
};

export default function ProfileWrapperClient({
  userProfile,
}: ProfileProps) {
  const t = useTranslations('Profile');

  return (
    <section className="row mt-14">
      <div className="container flex flex-col gap-8" key="container-profile">
        {userProfile?.sections?.map((item: UserSection, index: number) => {
          const modalSlug = slugify(item.section.name);
          return (
            <>
              {modalSlug === 'about' && item.isVisible ? (
                <ErrorBoundary
                  FallbackComponent={({ error }) => (
                    <ErrorSectionFallback error={error} section="profile-about" key={`about-${index}`} />
                  )}
                  onError={logError}
                  key={`about-error-boundary-${index}`} 
                >
                  <ProfileSectionAboutClient userProfile={userProfile} key={`about-${index}`} />
                </ErrorBoundary>
              ) : null}
              {modalSlug === 'total-followers' && item.isVisible ? (
                <ErrorBoundary
                  FallbackComponent={({ error }) => (
                    <ErrorSectionFallback error={error} section="profile-total-followers" key={`total-followers-${index}`} />
                  )}
                  onError={logError}
                  key={`total-followers-error-boundary-${index}`}
                >
                  <Suspense fallback={<SectionLoader key={`total-followers-${index}`} />} >
                    <ProfileSectionTotalFollowersClient userId={(userProfile as ApgUser).id} key={`total-followers-${index}`} />
                  </Suspense>
                </ErrorBoundary>
              ) : null}
              {modalSlug === 'achievements' && item.isVisible ? (
                <ErrorBoundary
                  FallbackComponent={({ error }) => (
                    <ErrorSectionFallback error={error} section="profile-achievements" key={`achievements-${index}`} />
                  )}
                  onError={logError}
                  key={`achievements-error-boundary-${index}`}
                >
                  <Suspense fallback={<SectionLoader key={`achievements-${index}`} />} >
                    <ProfileSectionAchievementsClient userId={(userProfile as ApgUser).id} key={`achievements-${index}`} />
                  </Suspense>
                </ErrorBoundary>
              ) : null}
              {modalSlug === 'events' && item.isVisible ? (
                <ErrorBoundary
                  FallbackComponent={({ error }) => (
                    <ErrorSectionFallback error={error} section="profile-events" key={`events-${index}`} />
                  )}
                  onError={logError}
                  key={`events-error-boundary-${index}`} 
                >
                  <Suspense fallback={<SectionLoader key={`events-${index}`} />} >
                    <ProfileSectionEventsClient userId={(userProfile as ApgUser).id} key={`events-${index}`} />
                  </Suspense>
                </ErrorBoundary>
              ) : null}
              {modalSlug === 'gallery' && item.isVisible ? (
                <ErrorBoundary
                  FallbackComponent={({ error }) => (
                    <ErrorSectionFallback error={error} section="profile-gallery" key={`gallery-${index}`} />
                  )}
                  onError={logError}
                  key={`gallery-error-boundary-${index}`}
                >
                  <Suspense fallback={<SectionLoader key={`gallery-${index}`} />} >
                    <ProfileSectionGalleriesClient userId={(userProfile as ApgUser).id} key={`gallery-${index}`} />
                  </Suspense>
                </ErrorBoundary>
              ) : null}
              {modalSlug === 'partners' && item.isVisible ? (
                <ErrorBoundary
                  FallbackComponent={({ error }) => (
                    <ErrorSectionFallback error={error} section="profile-partners" key={`partners-${index}`} />
                  )}
                  onError={logError}
                  key={`partners-error-boundary-${index}`}
                >
                  <Suspense fallback={<SectionLoader key={`partners-${index}`} />} >
                    <ProfileSectionPartnersClient userId={(userProfile as ApgUser).id} key={`partners-${index}`} />
                  </Suspense>
                </ErrorBoundary>
              ) : null}
              {modalSlug === 'setup' && item.isVisible ? (
                <ErrorBoundary
                  FallbackComponent={({ error }) => (
                    <ErrorSectionFallback error={error} section="profile-setup" key={`setup-${index}`} />
                  )}
                  onError={logError}
                  key={`setup-error-boundary-${index}`}
                >
                  <Suspense fallback={<SectionLoader key={`setup-${index}`} />} >
                    <ProfileSectionSetupClient userId={(userProfile as ApgUser).id} key={`setup-${index}`} />
                  </Suspense>
                </ErrorBoundary>
              ) : null}
              {modalSlug === 'games' && item.isVisible ? (
                <ErrorBoundary
                  FallbackComponent={({ error }) => (
                    <ErrorSectionFallback error={error} section="profile-games" key={`games-${index}`} />
                  )}
                  onError={logError}
                  key={`games-error-boundary-${index}`}
                >
                  <Suspense fallback={<SectionLoader key={`games-${index}`} />} >
                    <ProfileSectionGamesClient userId={(userProfile as ApgUser).id} key={`games-${index}`} />
                  </Suspense>
                </ErrorBoundary>
              ) : null}
              {modalSlug === 'videos' && item.isVisible ? (
                <ErrorBoundary
                  FallbackComponent={({ error }) => (
                    <ErrorSectionFallback error={error} section="profile-videos" key={`videos-${index}`} />
                  )}
                  onError={logError}
                  key={`videos-error-boundary-${index}`}
                >
                  <Suspense fallback={<SectionLoader key={`videos-${index}`} />} >
                    <ProfileSectionVideosClient userId={(userProfile as ApgUser).id} key={`videos-${index}`} />
                  </Suspense>
                </ErrorBoundary>
              ) : null}
              {modalSlug === 'twitch-player' && item.isVisible ? (
                <ErrorBoundary
                  FallbackComponent={({ error }) => (
                    <ErrorSectionFallback error={error} section="profile-twitch-player" key={`twitch-player-${index}`} />
                  )}
                  onError={logError}
                  key={`twitch-player-error-boundary-${index}`}
                >
                  <Suspense fallback={<SectionLoader key={`twitch-player-${index}`} />} >
                    <ProfileSectionTwitchPlayerClient userId={(userProfile as ApgUser).id} key={`twitch-player-${index}`} />
                  </Suspense>
                </ErrorBoundary>
              ) : null}
              {modalSlug === 'tiktok-feed' && item.isVisible ? (
                <ErrorBoundary
                  FallbackComponent={({ error }) => (
                    <ErrorSectionFallback error={error} section="profile-tiktok-feed" key={`tiktok-feed-${index}`} />
                  )}
                  onError={logError}
                  key={`tiktok-feed-error-boundary-${index}`}
                >
                  <Suspense fallback={<SectionLoader key={`tiktok-feed-${index}`} />} >
                    <ProfileSectionTiktokFeedClient userId={(userProfile as ApgUser).id} key={`tiktok-feed-${index}`} />
                  </Suspense>
                </ErrorBoundary>
              ) : null}
            </>
          )
        })}
        {userProfile?.sections && userProfile?.sections?.length === 0 ? (
          <div className="w-full max-w-md flex gap-4 justify-center mx-auto items-start mb-16 max-w">
            <div className="flex items-center">
              <h1 className="text-title-lg uppercase text-white">{t('empty-profile-here')}</h1>
              <Image src="/assets/resources/svg/empty-state-character.svg" alt="" width={140} height={220} />
            </div>
          </div>
        ) : null}
      </div>
    </section>
  )
}