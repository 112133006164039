"use client";

import { Events } from "@/components/Sections";
import { Event } from "@/domain/account/events.interface";
import { EventsEndpoints } from "@/graph/event/endpoints";
import useUserQuery from "@/hooks/useUserQuery";
import { useEffect, useState } from "react";

type ProfileProps = {
  userId: number;
};

export default function ProfileSectionEventsClient({
  userId,
}: ProfileProps) {
  const { data: events } = useUserQuery(userId, 'apg-user-events-section', EventsEndpoints.get<Event>, { userId, limit: 3 });
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    if (events?.items && events?.items.length > 0) {
      setIsDataReady(true);
    }
  }, [events]);

  return (
    <>
      {isDataReady ? (
        <div id="events" className="w-full">
          <Events data={events} />
        </div>
      ) : null}
    </>
  )
}