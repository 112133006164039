import { apgGraphQL } from "./apgApi"
import { operationProfileAchievement } from "./graph-queries";
import { RequestError } from "./general.endpoints";
import { InfinitePaginationResponse, PaginationResponse } from "@/domain/general.interface";
import { UserAchievement } from "@/domain/account/profile.interface";

export const getError = ({ title, message, response }: RequestError): RequestError => ({ title, message, response })

export interface EditProfileSetProps<T> {
  userId?: number;
  token?: string;
  data: T;
}

const get = async <T>({
  userId,
  token,
  limit = 4,
  page = 1,
  isEditing = false
}: any): Promise<PaginationResponse<T>> => {
  const offset = (page - 1) * limit;
  const showOnlyActive = !isEditing ? { "isActive": { "_eq": true } } : {};

  const { data, errors } = await apgGraphQL(
    operationProfileAchievement,
    'GetUserAchievements',
    {
      "where": {
        "userId": { "_eq": userId },
        ...showOnlyActive
      },
      "limit": limit,
      "offset": offset
    },
    token
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.pagination.data.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  return {
    items: data.userAchievements,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
    hasPreviousPage: page > 1,
    hasNextPage: page < totalPages
  };
}

const getInfinite = async <T>({
  userId,
  token,
  limit = 4,
  page = 1,
  isEditing = false
}: any): Promise<InfinitePaginationResponse<T>> => {
  const offset = (page - 1) * limit;
  const showOnlyActive = !isEditing ? { "isActive": { "_eq": true } } : {};

  const { data, errors } = await apgGraphQL(
    operationProfileAchievement,
    'GetUserAchievements',
    {
      "where": {
        "userId": { "_eq": userId },
        ...showOnlyActive
      },
      "limit": limit,
      "offset": offset
    },
    token
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.pagination.data.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  return {
    items: data.userAchievements,
    nextPage: page < totalPages ? page + 1 : null,
    previousPage: page > 0 ? page - 1 : null,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
  };
}

const insert = async ({ data: userAchievement, userId, token }: EditProfileSetProps<Partial<any>>): Promise<UserAchievement> => {
  const { isFeature, ...achievement } = userAchievement;

  const { data: { insertAchievement }, errors: er } = await apgGraphQL(
    operationProfileAchievement,
    'InsertAchievement',
    {
      "object": achievement
    },
    token
  )

  if (er) {
    throw getError(er[0] as RequestError)
  }

  if (!insertAchievement) {
    throw getError({
      title: 'Error',
      message: 'Achievement not found',
      response: {
        status: 404
      }
    })
  }

  const { id } = insertAchievement;

  const { data, errors } = await apgGraphQL(
    operationProfileAchievement,
    'InsertUserAchievement',
    {
      "object": {
        achievementId: id,
        userId,
        isFeature
      }
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.insertUserAchievement;
}

const update = async ({ data: userAchievement, userId, token }: EditProfileSetProps<Partial<any>>): Promise<UserAchievement> => {
  const { isFeature, isActive, id, ...achievement } = userAchievement;

  const { data: updateUserAchievement, errors: er } = await apgGraphQL(
    operationProfileAchievement,
    'UpdateUserAchievement',
    {
      "_set": {
        "isFeature": isFeature,
        "isActive": isActive
      },
      "userId": userId,
      "achievementId": id
    },
    token
  )

  if (er) {
    throw getError(er[0] as RequestError)
  }

  if (!updateUserAchievement) {
    throw getError({
      title: 'Error',
      message: 'Achievement not found',
      response: {
        status: 404
      }
    })
  }

  const { data, errors } = await apgGraphQL(
    operationProfileAchievement,
    'UpdateAchievement',
    {
      "_set": achievement,
      "id": id,
    },
    token
  )

  if (errors) {
    throw getError(er[0] as RequestError)
  }

  return data.updateAchievement;
}

const remove = async ({ achievementId, userId, token }: any): Promise<boolean> => {
  const { errors } = await apgGraphQL(
    operationProfileAchievement,
    'DeleteUserAchievement',
    {
      "achievementId": achievementId,
      "userId": userId
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return true;
}

export const AchievementsEndpoints = {
  get,
  getInfinite,
  insert,
  update,
  remove
}
