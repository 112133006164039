import { operationRedeem } from "./graph-queries";
import { apgGraphQL } from "../apgApi";
import { RequestError } from "../general.endpoints";
import { RawDraftContentState } from "draft-js";
import { operationProfileEvent, operationProfileVouch } from "../graph-queries";
import { Event } from "@/domain/account/events.interface";

export const getError = ({ title, message, response }: RequestError): RequestError => ({ title, message, response })

export type UserCommon = {
  userId?: number;
  token?: string;
  redeemId?: number;
}

export type UserMissionParams = UserCommon & {
  status?: string[];
}

export type ClaimRewardParams = UserCommon & {
  vouchedUserId: number;
  testimonial: RawDraftContentState;
  cost: number;
}

export type EventRewardParams = UserCommon & {
  event: Partial<Event>;
  cost: number;
}

const vouch = async ({
  userId,
  redeemId,
  vouchedUserId,
  testimonial,
  cost,
  token
}: ClaimRewardParams): Promise<{
  success: boolean;
  message: string;
}> => {
  const { data: validateCoin, errors } = await apgGraphQL(
    operationRedeem,
    'ValidateUserCoins',
    {
      "payload": {
        "senderUserId": userId,
        "cost": cost,
        "redeemId": redeemId,
      }
    }, 
    token
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  if (!validateCoin.updateUserCoins.success) {
    return {
      success: false,
      message: 'YOU_DO_NOT_HAVE_ENOUGH_COINS_TO_VOUCH_FOR_THIS_USER'
    }
  }

  const { data, errors: errorsVouch } = await apgGraphQL(
    operationProfileVouch,
    'Vouch',
    {
      "vouchedUserId": vouchedUserId,
      "testimonial": testimonial
    },
    token
  )

  if (errorsVouch) {
    throw getError(errors[0] as RequestError)
  }

  return data.follow.id ? {
    success: true,
    message: 'VOUCH_SUCCESSFULLY'
  } : {
    success: false,
    message: 'VOUCH_FAILED'
  };
}

const event = async ({
  userId,
  event,
  cost,
  redeemId,
  token
}: EventRewardParams): Promise<{
  success: boolean;
  message: string;
}> => {
  const { data: validateCoin, errors } = await apgGraphQL(
    operationRedeem,
    'ValidateUserCoins',
    {
      "payload": {
        "senderUserId": userId,
        "cost": cost,
        "redeemId": redeemId,
      }
    }, 
    token
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  if (!validateCoin.updateUserCoins.success) {
    return {
      success: false,
      message: 'YOU_DO_NOT_HAVE_ENOUGH_COINS_TO_CRETAE_EVENT'
    }
  }

  const { data: { insertEvent }, errors: errorsEvent } = await apgGraphQL(
    operationProfileEvent,
    'InsertEvent',
    {
      "object": event
    },
    token
  )

  if (errorsEvent) {
    throw getError(errors[0] as RequestError)
  }

  return insertEvent ? {
    success: true,
    message: 'EVENT_CREATED_SUCCESSFULLY'
  } : {
    success: false,
    message: 'EVENT_CREATION_FAILED'
  };
}

export const RedeemEndpoints = {
  vouch,
  event
}
