import { UseQueryOptions, useSuspenseQuery } from "@tanstack/react-query";

const useUserQuery = <T extends unknown>(userId: number, key: string, fetchFunction: (params: any) => Promise<T>, params: any, options?: UseQueryOptions<T>) => {
  return useSuspenseQuery<T>({ 
    queryKey: [key, userId.toString()],
    queryFn: () => fetchFunction(params),
    staleTime: 1000 * 60 * 10, 
    ...options 
  });
};

export default useUserQuery;