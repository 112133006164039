"use client";

import { ApgUser } from "@/domain/account.interface";
import { db, RecentProfile } from "@/lib/utils/database.config";
import delay from "@apg.gg/core/lib/delay";
import { useLiveQuery } from "dexie-react-hooks";
import { useParams } from "next/navigation";
import { useState, useEffect } from "react";
import useGlobalStore from "@/store/global.store";

type Props = {
  userProfile: ApgUser | null;
}

export default function RecentProfileClient({ 
  userProfile
 }: Props ) {
  const params = useParams();
  const { profile } = params;
  const recentProfiles = useLiveQuery(() => db.recentProfiles.get("user:local:recentProfiles"));
  const [isRecentPageAdded, setIsRecentPageAdded] = useState(false);
  const { setUserProfile } = useGlobalStore();

  useEffect(() => {
    delay(() => {
      if ((recentProfiles?.profiles && !isRecentPageAdded) || (!recentProfiles && !isRecentPageAdded)) {
        const recentPageItem = {
          key: String(userProfile?.username),
          name: userProfile?.name || 'APG Profile',
          icon: userProfile?.profileImage || process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC,
          iconHoverColor: "blue-300",
          bgColor: "black-600",
          activeBgColor: "blue",
          href: `/${userProfile?.username}`,
        } as RecentProfile;
    
        let recentProfilesArray = recentProfiles?.profiles || [];
    
        if (userProfile) {
          const existingIndex = recentProfilesArray.findIndex((item: RecentProfile) => item.key === userProfile?.username);
    
          if (existingIndex !== -1) {
            recentProfilesArray.splice(existingIndex, 1);
          }
    
          recentProfilesArray = [recentPageItem, ...recentProfilesArray];
    
          const addRecentProfile = async () => {
            try {
              await db.recentProfiles.put({
                profiles: recentProfilesArray,
              }, 'user:local:recentProfiles');
            } catch (error) {
              console.error(`Failed to add recentProfile: ${error}`);
            }
          }
    
          addRecentProfile();
          setIsRecentPageAdded(true);
        }
      }
    }, 1500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentProfiles, isRecentPageAdded])
  
  useEffect(() => {
    setIsRecentPageAdded(false);
  }, [profile]);

  useEffect(() => {
    setUserProfile(userProfile as ApgUser);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);
  
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}