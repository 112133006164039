"use client";

import { Setup } from "@/components/Sections";
import { UserHardware } from "@/domain/account/setup.interface";
import { HardwaresEndpoints } from "@/graph/profile-hardwarers.endpoints";
import useUserQuery from "@/hooks/useUserQuery";
import { useState, useEffect } from "react";

type ProfileProps = {
  userId: number;
};

export default function ProfileSectionSetupClient({
  userId,
}: ProfileProps) {
  const { data: setup } = useUserQuery(userId, 'apg-user-setup-section', HardwaresEndpoints.get<UserHardware>, { userId });
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    if (setup?.items && setup?.items.length > 0) {
      setIsDataReady(true);
    }
  }, [setup]);

  return (
    <>
      {isDataReady ? (
        <div id="setup" className="w-full">
          <Setup data={setup} />
        </div>
      ) : null}
    </>
  )
}